"use client";

import { GE, GTMEvent } from ".constants/gtmEvents";
import { PATH_BUY } from ".constants/links";
import {
  ReverseDiscount,
  SubscribePack,
  SubscribePropsItem,
} from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC, memo } from "react";
import { useCookies } from "react-cookie";

import { GetMethodsQuery } from "@/.gql/graphql";
import { setCookie } from "@/app/_actions/setCookie";
import { useConfigContext, useGTMContext } from "@/providers";
import { getBeforeDiscount } from "@/utils/getDiscount";
import { getLocalePrice } from "@/utils/getLocalPrice";

// >>> <LandingSubscribePack/>
export const LandingSubscribeFreePack: FC = memo(() => {
  const l = useTranslations("Landing");
  const t = useTranslations("SubscribePage");
  const { sendGTM } = useGTMContext();
  const { freeCoinsAfterRegister } = useConfigContext();
  const [cookies] = useCookies([
    "rulesAccepted",
    "ga_ab",
    "ga_abc",
    "segment_ab",
  ]);
  const rulesAccepted = Number(cookies.rulesAccepted) === 1;

  const onTryFree = async () => {
    await setCookie("app", "1", {
      maxAge: 60 * 60 * 24 * 1, // примерно 1 день в секундах
    });
    window.scrollTo({
      top: 0,
    });
    sendGTM({ event: GE.CHOOSETRYFREE });
  };

  return (
    <SubscribePack
      coins={freeCoinsAfterRegister}
      title={t("free.title")}
      desc={t("free.desc", { coins: freeCoinsAfterRegister })}
      buttonColor="orange"
      color="regular"
      buttonText={l("tryForFree")}
      type="free"
      onClick={onTryFree}
      disabled={!rulesAccepted}
    >
      <SubscribePropsItem
        icon={t("free.prop1.icon")}
        text={t("free.prop1.text")}
      />
      <SubscribePropsItem
        icon={t("free.prop2.icon")}
        text={t("free.prop2.text")}
      />
      <SubscribePropsItem
        icon={t("free.prop3.icon")}
        text={t("free.prop3.text")}
      />
    </SubscribePack>
  );
});
LandingSubscribeFreePack.displayName = "LandingSubscribeFreePack";
// <<< <LandingSubscribeFreePack/>

// >>> <LandingSubscribePack/>
type TLandingSubscribePackProps = {
  coinPack: GetMethodsQuery["getPacks"][0];
  index: 0 | 1 | 2 | 3;
  disabled?: boolean;
  event?: GTMEvent;
};
export const LandingSubscribePack: FC<TLandingSubscribePackProps> = memo(
  ({ coinPack, index, disabled, event }) => {
    const t = useTranslations("SubscribePage");
    const { sendGTM } = useGTMContext();

    const onBuyCoins = () => {
      sendGTM({
        event: event ?? GE.LANDING_PACKAGE_CHOICE_VALUE,
        ecommerce: {
          currency: coinPack.currency,
          value: coinPack.price,
        },
      });
    };

    const dicsountForAB = index === 3 ? 90 : coinPack.discount;

    return (
      <SubscribePack
        coins={coinPack.coin}
        href={`${PATH_BUY}/${coinPack.coin}`}
        scroll={false}
        title={t(`${index}.title`)}
        desc={t(`${index}.desc`, {
          coins: coinPack.coin,
        })}
        buttonText={
          <>
            {coinPack.discount && coinPack.discount > 0 ? (
              <>
                <ReverseDiscount
                  text={getLocalePrice(
                    coinPack.currency,
                    getBeforeDiscount(coinPack.price, dicsountForAB),
                  )}
                />
                &nbsp;&nbsp;
              </>
            ) : null}

            {getLocalePrice(coinPack.currency, coinPack.price)}
          </>
        }
        sale={
          coinPack.discount > 0 &&
          t.rich("sale", {
            sale: dicsountForAB,
          })
        }
        color={[2, 3].includes(index) ? "orange" : "violet"}
        type="vip"
        onClick={onBuyCoins}
        disabled={disabled}
      >
        <SubscribePropsItem
          icon={t(`${index}.prop1.icon`)}
          text={t(`${index}.prop1.text`)}
          border
        />
        <SubscribePropsItem
          icon={t(`${index}.prop2.icon`)}
          text={t(`${index}.prop2.text`)}
        />
        <SubscribePropsItem
          icon={t(`${index}.prop3.icon`)}
          text={t(`${index}.prop3.text`)}
        />

        {[2, 3].includes(index) ? (
          <>
            <SubscribePropsItem
              icon={t(`${index as 2 | 3}.prop4.icon`)}
              text={t(`${index as 2 | 3}.prop4.text`)}
            />
          </>
        ) : null}
      </SubscribePack>
    );
  },
);
LandingSubscribePack.displayName = "LandingSubscribePack";
// <<< <LandingSubscribePack/>
