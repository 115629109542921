"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_HOME, PATH_TG_BOT } from ".constants/links";
import { Box, Button } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";
import { useCookies } from "react-cookie";

import { setCookie } from "@/app/_actions/setCookie";
import { usePathname, useRouter } from "@/i18n";
import { useMobileContext } from "@/providers";
import IconTG from "@/styles/image/social-buttons/telegram.svg";

type TAuthButtonsProps = {
  men?: boolean;
};
export const AuthButtons: FC<TAuthButtonsProps> = ({ men }) => {
  const t = useTranslations("Landing");
  const s = useTranslations("SigninPage");
  const { isMobiles } = useMobileContext();
  const pathname = usePathname();
  const [cookies] = useCookies([
    "_r",
    "rulesAccepted",
    "app",
    "sens",
    "ga_ab",
    "ga_abc",
    "segment_ab",
  ]);
  const router = useRouter();

  const onTryFree = async () => {
    await setCookie("app", "1", {
      maxAge: 60 * 60 * 24 * 1, // примерно 1 день в секундах
    });
    if (pathname !== PATH_HOME) {
      router.push(PATH_HOME);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <Box gap={isMobiles ? 8 : 16}>
      {men ? (
        <Button
          href={PATH_TG_BOT}
          text={s("telegramBot")}
          iconLeft={IconTG}
          size="large"
          color="telegram"
          wide
          centerText
          data-event={GE.CHOOSE_TG}
          disabled={!cookies.rulesAccepted}
        />
      ) : (
        <Button
          text={t("undress")}
          size="large"
          wide
          centerText
          data-event={GE.CHOOSETRYFREE}
          disabled={!cookies.rulesAccepted}
          onClick={onTryFree}
        />
      )}
    </Box>
  );
};
