"use client";

import { Box, PaginationDot } from "@cloai/uikit";
import clsx from "clsx";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel, { UseEmblaCarouselType } from "embla-carousel-react";
import { useTranslations } from "next-intl";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image from "next/image";
import { FC, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import styles from "@/styles/pages/sliderV2.module.scss";

type TSliderV2SourceProps = {
  girls: {
    clothed: StaticImport;
    nudes?: StaticImport;
    pose1?: StaticImport;
    pose2?: StaticImport;
    pose3?: StaticImport;
    foxy?: boolean;
    anime?: boolean;
    pose?: boolean;
  }[];
};
export const SliderV2Source: FC<TSliderV2SourceProps> = ({ girls }) => {
  const t = useTranslations("Landing");
  const [cookies] = useCookies(["sens", "rulesAccepted"]);
  const rulesAccepted = Number(cookies.rulesAccepted) === 1;

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, align: "start", dragFree: true },
    [
      Autoplay({
        delay: 3000,
        active: rulesAccepted,
      }),
    ],
  );

  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: UseEmblaCarouselType[1]) => {
    setScrollSnaps(emblaApi?.scrollSnapList() ?? []);
  }, []);

  const onSelect = useCallback((emblaApi: UseEmblaCarouselType[1]) => {
    setSelectedIndex(emblaApi?.selectedScrollSnap() ?? 0);
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <div className={styles.slider_box}>
      <div className={styles.slider} ref={emblaRef}>
        <Box gap={0} row className={styles.viewport}>
          {girls.map((girl, index) => (
            <div
              className={clsx(styles.item, {
                [styles._active]: index === selectedIndex,
              })}
              key={index}
            >
              <div className={styles.inner}>
                <div className={styles.photo_block}>
                  {girl.anime && (
                    <div className={styles.tag}>
                      <span>Anime</span>
                      <span
                        className={clsx("material-icons", styles.icon)}
                        data-icon={"diamond"}
                      />
                    </div>
                  )}

                  {girl.pose && (
                    <div className={styles.tag}>
                      <span>Pose</span>
                      <span
                        className={clsx("material-icons", styles.icon)}
                        data-icon={"diamond"}
                      />
                    </div>
                  )}

                  {girl.foxy && (
                    <div className={styles.tag}>
                      <span>
                        Sweetie<span style={{ color: "#FF7223" }}>Fox</span>
                      </span>
                      <span className={styles.icon}>🦊</span>
                    </div>
                  )}

                  <Image
                    sizes="50vw"
                    className={styles.girl_default}
                    src={girl.clothed}
                    alt={t("imgMeta.alt")}
                    fetchPriority={index === 0 ? "high" : "low"}
                    loading={
                      index === selectedIndex || index === selectedIndex + 1
                        ? "eager"
                        : "lazy"
                    }
                    // blurDataURL={girl.blurClothed}
                    // priority={[3, 0, 1].includes(index) && rulesAccepted}
                    placeholder="blur"
                  />

                  {girl.nudes ? (
                    <div className={styles.girl_nudes}>
                      <Image
                        sizes="50vw"
                        className={styles.girl_nudes__img}
                        src={girl.nudes}
                        alt={t("imgMeta.alt")}
                        fetchPriority="low"
                        loading={
                          index === selectedIndex || index === selectedIndex + 1
                            ? "eager"
                            : "lazy"
                        }
                        // blurDataURL={girl.blurUnclothed}
                        // priority={[3, 0, 1].includes(index) && rulesAccepted}
                        placeholder="blur"
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className={clsx(styles.girl_pose, styles.girl_pose__1)}
                      >
                        <Image
                          sizes="50vw"
                          className={styles.girl_nudes__img}
                          src={girl.pose1!}
                          alt={t("imgMeta.alt")}
                          fetchPriority="low"
                          loading={
                            index === selectedIndex ||
                            index === selectedIndex + 1
                              ? "eager"
                              : "lazy"
                          }
                          placeholder="blur"
                        />
                      </div>

                      <div
                        className={clsx(styles.girl_pose, styles.girl_pose__2)}
                      >
                        <Image
                          sizes="50vw"
                          className={styles.girl_nudes__img}
                          src={girl.pose2!}
                          alt={t("imgMeta.alt")}
                          fetchPriority="low"
                          loading={
                            index === selectedIndex ||
                            index === selectedIndex + 1
                              ? "eager"
                              : "lazy"
                          }
                          placeholder="blur"
                        />
                      </div>

                      <div
                        className={clsx(styles.girl_pose, styles.girl_pose__3)}
                      >
                        <Image
                          sizes="50vw"
                          className={styles.girl_nudes__img}
                          src={girl.pose3!}
                          alt={t("imgMeta.alt")}
                          fetchPriority="low"
                          loading={
                            index === selectedIndex ||
                            index === selectedIndex + 1
                              ? "eager"
                              : "lazy"
                          }
                          placeholder="blur"
                        />
                      </div>
                    </>
                  )}

                  <div className={styles.scan_line_white} />
                  <div className={styles.scan_line} />
                </div>
              </div>
            </div>
          ))}
        </Box>
      </div>

      <div className={styles.pagination}>
        {scrollSnaps.map((_, index) => (
          <PaginationDot
            key={index}
            aria-label={`Scroll to slide ${index + 1}`}
            onClick={() => scrollTo(index)}
            active={index === selectedIndex}
          />
        ))}
      </div>
    </div>
  );
};
