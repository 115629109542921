"use client";

import { GE } from ".constants/gtmEvents";
import { PATH_EARN } from ".constants/links";
import { Button } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";
import { useCookies } from "react-cookie";

import { useGTMContext } from "@/providers";

export const LandingEarnCoinButton: FC = () => {
  const t = useTranslations("Landing");
  const { sendGTM } = useGTMContext();
  const [cookies] = useCookies([
    "rulesAccepted",
    "ga_ab",
    "ga_abc",
    "segment_ab",
  ]);

  const onEarnCoins = () => {
    sendGTM({ event: GE.LANDING_ADVANTAGE_EARN_COINS_BUTTON });
  };

  return (
    <Button
      href={PATH_EARN}
      iconLeft="toll"
      text={t("Advantage.clause1.buttonText")}
      wide
      centerText
      color="violet"
      onClick={onEarnCoins}
      disabled={!cookies.rulesAccepted}
    />
  );
};
