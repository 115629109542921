"use client";

import { EC_COUNTRIES_LIST } from ".constants/texts";
import { Box, Button, RulesBLock, Subtitle, Text } from "@cloai/uikit";
import _ from "lodash";
import { useTranslations } from "next-intl";
import { useCookies } from "react-cookie";

import { useHeadersContext, useMobileContext } from "@/providers";

type RulesBlockProps = {
  className?: string;
  onAccept?: () => void;
};

export const RulesBlock = ({ className, onAccept }: RulesBlockProps) => {
  const t = useTranslations("RulesBlock");
  const { isMobiles } = useMobileContext();
  const [cookies, setCookies] = useCookies([
    "rulesAccepted",
    "segment_ab",
    "ga_ab",
  ]);
  const headers = useHeadersContext();
  const country =
    headers["ddg-connecting-country"] ??
    headers["x-mock-country"] ??
    headers["cf-ipcountry"] ??
    "";

  const EUcountry = EC_COUNTRIES_LIST.includes(country);

  const { rulesaccepted: rulesAccepted } = useHeadersContext();

  const isRulesAccepted =
    _.toInteger(cookies.rulesAccepted ?? rulesAccepted) === 1;

  const onClick = () => {
    setCookies("rulesAccepted", 1);
    onAccept?.();
  };

  if (isRulesAccepted) {
    return null;
  }

  return (
    <RulesBLock
      theme={
        EUcountry ? (isMobiles ? "darkblue" : "darkblue_w_blackout") : "none"
      }
      className={className}
    >
      <div className="mx-auto flex w-full max-w-108 flex-col gap-4">
        <Subtitle text={t(EUcountry ? "eu_h1" : "h1")} center fontSize={28} />

        {EUcountry ? (
          <>
            <ul>
              <Box gap={8}>
                <Text Component="li" text={t("eu_step1")} fontSize={16} />
                <Text Component="li" text={t("eu_step2")} fontSize={16} />
                <Text Component="li" text={t("eu_step3")} fontSize={16} />
                <Text Component="li" text={t("eu_step4")} fontSize={16} />
              </Box>
            </ul>

            <ul style={{ opacity: "0.32" }}>
              <Text Component="li" text={t("notice1")} fontSize={12} />
              <Text Component="li" text={t("notice2")} fontSize={12} />
              <Text Component="li" text={t("notice3")} fontSize={12} />
            </ul>
          </>
        ) : (
          <ul>
            <Box>
              <Text Component="li" text={t("step1")} />
              <Text Component="li" text={t("step2")} />
              <Text Component="li" text={t("step3")} />
              <Text Component="li" text={t("step4")} />
            </Box>
          </ul>
        )}
        <Button
          text={t("accept")}
          iconLeft="check"
          wide
          centerText
          onClick={onClick}
        />
      </div>
    </RulesBLock>
  );
};
