"use client";

import { GE } from ".constants/gtmEvents";
import { Button } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { FC } from "react";
import { useCookies } from "react-cookie";

import { setCookie } from "@/app/_actions/setCookie";
import { useGTMContext } from "@/providers";

export const LandingTryFreeButton: FC = () => {
  const t = useTranslations("Landing");
  const { sendGTM } = useGTMContext();
  const [cookies] = useCookies([
    "rulesAccepted",
    "ga_ab",
    "ga_abc",
    "segment_ab",
  ]);
  const rulesAccepted = Number(cookies.rulesAccepted) === 1;

  const onTryFree = async () => {
    await setCookie("app", "1", {
      maxAge: 60 * 60 * 24 * 1, // примерно 1 день в секундах
    });
    window.scrollTo({
      top: 0,
    });
    sendGTM({ event: GE.CHOOSETRYFREE });
  };

  return (
    <Button
      iconLeft="language"
      text={t("tryForFree")}
      size="large"
      wide
      centerText
      disabled={!rulesAccepted}
      onClick={onTryFree}
    />
  );
};
