// получаем число ПОСЛЕ вычета n% скидки
export const getAfterDiscount = (sum: number, discount: number) =>
  Math.round((sum / 100) * (100 - discount));

// получаем число + n% бонуса
export const getBonus = (sum: number, discount: number) =>
  Math.round((sum / (100 + discount)) * 100);

// получаем число ДО вычета n% скидки
export const getBeforeDiscount = (sum: number, discount: number) =>
  Math.round((sum / (100 - discount)) * 100);
