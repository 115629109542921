"use client";

import "dayjs/locale/ru";
import { PATH_BLOG } from ".constants/links";
import { BlogItem, Box, BoxScrolled } from "@cloai/uikit";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import _ from "lodash";
import { useLocale } from "next-intl";
import { FC } from "react";

import { usePostsContext, PostsProvider, TagsProvider } from "@/providers";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export const LandingBlogPosts: FC = () => (
  <>
    <span />
    <TagsProvider>
      <PostsProvider>
        <BoxScrolled gap={12} direction="horizontal" offsetOut={12}>
          <Box gap={12} row style={{ minWidth: "948px" }}>
            <Posts />
          </Box>
        </BoxScrolled>
      </PostsProvider>
    </TagsProvider>
  </>
);

const Posts: FC = () => {
  const { posts } = usePostsContext();
  const locale = useLocale();

  return (
    <>
      {_.map(posts?.slice(0, 3), (post) => {
        const tags = _.map(post?.tags, "name");

        return (
          <BlogItem
            key={post!.id}
            href={`${PATH_BLOG}/${post!.url!}`}
            image={{
              src: post!.preview!.url,
              alt: post!.title!,
              width: 300,
              height: 200,
            }}
            title={post!.title!}
            tags={tags as string[]}
            date={dayjs(post!.publishDate).locale(locale).fromNow()}
          />
        );
      })}
    </>
  );
};
