"use client";

import { FC } from "react";
import { useCookies } from "react-cookie";

import { usePaymentContext } from "@/providers";

import { LandingSubscribePack } from "./LandingSubscribePack";

export const LandingSubscribePackList: FC = () => {
  const [cookies] = useCookies(["rulesAccepted", "app"]);
  const rulesAccepted = Number(cookies.rulesAccepted) === 1;
  const { userPacksList } = usePaymentContext();

  if (!userPacksList) {
    return null;
  }

  return userPacksList
    .slice(0, 4)
    .map((coinPack, Index) => (
      <LandingSubscribePack
        key={Index}
        index={Index as 0 | 1 | 2 | 3}
        coinPack={coinPack}
        disabled={!rulesAccepted}
      />
    ));
};
